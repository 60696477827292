import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { Auth0LoginErrorEnum } from '../../../shared/models/user.model';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CallbackResolver {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  resolve(): Observable<void> {
    return this.authService.handleAuthCallback().pipe(
      catchError(_ => {
        this.authService.logout();
        return EMPTY;
      }),
      concatMap(result => {
        if (result.loggedIn && !result.errorType) {
          this.router.navigate([result.targetUrl]);
          return EMPTY;
        } else if (!result.loggedIn && !result.errorType) {
          this.authService.login();
          return EMPTY;
        } else {
          if (result.errorType === Auth0LoginErrorEnum.verifyEmail) {
            this.authService.logout(result.targetUrl);
            return EMPTY;
          } else {
            this.authService.logout();
            return EMPTY;
          }
        }
      }),
      take(1)
    );
  }
}
